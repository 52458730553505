.btn-with-icon {
  display: inline-flex;
  gap: $default-button-icon-gap;
  align-items: center;
  justify-content: center;

  .material-icons {
    font-size: $default-button-icon-size;
  }

  &.btn-sm {
    gap: $small-button-icon-gap;

    .material-icons {
      font-size: $small-button-icon-size;
    }
  }

  &.btn-lg {
    gap: $large-button-icon-gap;

    .material-icons {
      font-size: $large-button-icon-size;
    }
  }
}

.btn-unstyle {
  padding: 0;
  text-align: inherit;
  background-color: transparent;
  border: none;

  &:focus {
    outline: 0;

    .expand-more {
      color: var(--bs-primary);
    }
  }
}

.btn {
  
  .btn-check:focus + &,
  &:focus {
  	text-decoration:none;
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow:none;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.show {
    box-shadow:none;

    &:focus {
    	text-decoration:none;
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow:none;
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow:none;
  }
}

.btn-primary{
	background:$secondary;
	border-color:$secondary;
	font-weight:700;
  font-size:$small-font-size;
  text-transform:uppercase;
	&:hover{
		background:$white;
		color:$secondary;
	}
}

.btn-secondary{
	font-weight:700;
  font-size:$small-font-size;
  text-transform:uppercase;
	@extend .btn-outline-secondary;
	background:$white;
}

.btn-default{
	@extend .btn-primary;	
}
;@import "sass-embedded-legacy-load-done:876";