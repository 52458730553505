$component-name: content;

.#{$component-name} {
  &-columns {
    display: flex;
    align-items: stretch;
    padding: 0 3.75rem;

    &-right {
      .h2 {
        text-transform: inherit;
      }
    }
  }
}

##{$component-name}-wrapper {
  .page-footer {
    margin-top: 3rem;
    #index &{margin-top:0;}

    > .container {
      > a:last-of-type:not(:first-of-type) {
        float: right;
      }
    }

    a {
      margin-right: 1rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1062";