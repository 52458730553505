// Button with icon
$default-button-icon-size: 1.5rem;
$default-button-icon-gap: 0.25rem;

$small-button-icon-size: 1.25rem;
$small-button-icon-gap: 0.125rem;

$large-button-icon-size: 1.875rem;
$large-button-icon-gap: 0.375rem;

;@import "sass-embedded-legacy-load-done:747";