.alert {
  &.fade:not(.show) {
    @extend .visually-hidden;
  }

  p,
  ul,
  ol {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:904";