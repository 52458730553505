.main-menu {
  order: -1;

  @include media-breakpoint-up(xl) {
    order: 1;
  }

  &__tree {
    > li {
      > a {
        padding: 1.5rem 1rem;
        outline-offset: -0.5rem;
      }

      &:hover {
        .menu-container {
          display: block;
        }
      }

      .menu-container {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: $zindex-offcanvas;
        display: none;
        padding: 0.725rem 0;
        background-color: #fff;

        &.focusing,
        &:focus-within {
          display: block;
        }

        a {
          &[data-depth="1"],
          &[data-depth="2"] {
            overflow-wrap: break-word;
            white-space: normal;
          }

          &[data-depth="1"] {
            font-weight: 600;
            color: $dropdown-link-color;

            &:hover {
              &,
              i {
                color: var(--bs-primary);
              }
            }

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}


;@import "sass-embedded-legacy-load-done:1044";