$component-name: step;

.#{$component-name} {
  .address {
    

    &,
    &__content {
      cursor: pointer;
    }

    &.selected {
      border: 1px solid $primary;
    }
    &__actions{
  		margin-top:0.5rem;
  		margin-bottom:0rem;
  	}
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: var(--bs-gray-100);
    border-radius: var(--bs-border-radius-sm);

    dd,
    dt {
      width: 50%;
      padding: 1rem 0;
      margin: 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--bs-gray-300);
      }
    }

    dd {
      text-align: right;
    }
  }
}


.cart-grid-right .accordion-item{
	border:0px;	
}


.carrier-name{
	font-weight:700;
}

#checkout-personal-information-step{
	.nav-tabs{
		@include media-breakpoint-desktop(){
		  width: 75%;
	    margin: 0 auto;
		}	
	}	
}


.payment__option .form-check-round+img{display:none;}

a#cta-terms-and-conditions-0{
	text-decoration:underline;
	text-underline-position:under;
}
;@import "sass-embedded-legacy-load-done:1072";