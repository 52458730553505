$component-name: block-category;

.#{$component-name} {

  #category-description {
    font-size: 1rem;
  }
  .category-text{
		h1 {
	   		color:$body-color;		
	  }
	}
  .category-cover{
  	background: #1f1103;	
  	@include media-breakpoint-mobile(){
  			background-size:cover;
  			background-repeat: no-repeat;
  			background-position:center;
  	}
  	img{
  		opacity:0.6;	
  		width:100%;

  	}
  	&+.category-text{
			position:absolute;	
			left: 0;
	    right: 0;
	    top: 50%;
	    transform: translateY(-25%);
	    bottom: auto;
	  }
	  &+.category-text,& #_mobile_category_desc {
	    h1 {
   		color:$gray-100;	
  	 }
  	  #category-description {
    	font-size: 1rem;
    	color: var(--bs-gray-100);
  		}
  	}
  }
  
}

;@import "sass-embedded-legacy-load-done:981";