.login {
  .forgot-password,
  &-create {
    text-align: center;
  }

  hr {
    margin: 1rem auto;
  }

  .forgot-password {
    margin-bottom: 1.25rem;
  }
}

;@import "sass-embedded-legacy-load-done:1068";