$component-name: cart;

.#{$component-name} {
  &-overview {
    &:focus-visible {
      outline: 0;
    }
  }

  &-summary {
    padding: 1rem;

    .card-footer {
      padding: 0;
      padding-top: 1rem;
    }

    &,
    .card-footer {
      background-color: lighten($gray-100, 2%);
    }

    &__container {
      .card-header,
      .card-footer {
        background-color: var(--bs-white);

        i {
          color: inherit;
        }
      }
    }

    &__products {
      margin-bottom: 0.75rem;
      border-bottom: 1px solid var(--bs-gray-300);

      > p {
        padding: 1rem 0;
        margin-bottom: 0;

        &:first-of-type {
          padding-top: 0.5rem;
          border-bottom:0px solid var(--bs-gray-300);
        }
      }

      .cart-summary__product {
      	.product-name{
      		font-family:$headings-font-family;	
      	}
        &__current {
          margin-bottom: 0.5rem;
        }
        &__basic{
        color:var(--bs-gray-500);	
        }

        &__body {
          .label,
          .value {
            font-size: 1rem;
            font-weight: 400;
            color: var(--bs-body-color);
          }

          .product-line-info {
            margin: 0.5rem 0;
          }

          .product-name {
            a {
              /*font-weight: 600;*/
              color: var(--bs-body-color);

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
      .float-sm-start{margin-top:0.15rem;}
    }

    &__show {
      font-weight: 500;

      i {
        color: var(--bs-primary);
      }

      span {
        vertical-align: middle;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    &__totals {
      padding-top: 1rem;
      margin-top: 0.75rem;
      border-top: 1px solid var(--bs-gray-300);

      span {
        font-weight: 700;
      }
    }

    &__line,
    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      span {
        font-size: 1rem;

        &.value {
          color: var(--bs-body-color);
        }
      }
    }
  }

  &-detailed {
    &__actions {
      padding-top: 0;

      .btn-primary {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__item {
    .product-line {
      &__title {
        align-self: start;
        width: 100%;
        font-weight: 600;
        color: var(--bs-body-color);
      }

      &__image {
        img {
          @include border-radius();
        }
      }

      &__item {
        display: block;
        margin-bottom: 0.5rem;
      }

      &__current {
        span {
          /*font-weight: 600;*/
        }
      }

      &__regular {
        color: var(--bs-gray-600);
        text-decoration: line-through;
      }

      &__basic {
        .badge {
          padding: 0.25rem;
        }
      }
      &__informations{
      	padding: 0.25rem;
      	.price{
      		font-weight:700;	
      	}
      }

      &__actions {
        a {
          display: inline-block;
          padding: 0.5rem;
          margin-left: -0.5rem;
          color:var(--bs-primary);

          &::after {
            display: block;
            width: 100%;
            height: 1px;
            content: "";
            background: var(--bs-primary);
            transition: 0.25s ease-out;
          }

          &:hover {
            &::after {
              opacity: 0;
            }
          }
        }
      }

      hr {
        margin-top: 0.75rem;
      }
    }
  }
}

.cart-voucher{
	  text-align: center;
	  a.accordion-button{
	    justify-content: center;
	  	&:after{display:none;}
	  }
	  .accordion-button[aria-expanded=true] {
	    display: none;
	  }
	  .js-code{
	  		font-weight:700;
	  		text-decoration:underline;
	  		text-underline-position:under;
	  }
}

;@import "sass-embedded-legacy-load-done:997";