$component-name: header;

.#{$component-name} {
  --header-bg: #{$header-bg};
  --header-top-bg: #{$header-top-bg};
  --header-color: #{$header-color};
  --header-top-color: #{$header-top-color};
  

 /* position: sticky;
  top: 0;
  z-index: 100;*/
 /* width: 100%;
  max-width: 100vw;*/
  transition: all 0.5s linear;
  background-color: var(--header-bg);
  &.header--fixed{
		position: fixed;
		z-index: 1045;	
		width: 100%;
	}
  &.header--bg-transparent.headroom--top{
	  background-color: transparent;
		color:$white;
		.btn{
			color:$white;	
		}
	}

  a:not(.dropdown-item):not(.btn) {
    color: var(--header-color);

    &:hover {
      &,
      i {
        color: var(--bs-primary);
      }
    }
  }
}



.headroom {
   /* will-change: transform;
    transition: transform 200ms linear;*/
}
.headroom--unpinned,
.headroom--pinned.headroom--not-top {
	 background-color:var(--header-bg);
	 @include media-breakpoint-desktop{
   	transform: translateY(-96px); //-144 ou -111  si on translate logo
 	 }
 	 @include media-breakpoint-mobile{
 	 	transform: translateY(-31px); // pour cacher banner-top
 	 }
   position: fixed;
   width: 100%;
   top:0;
   max-width:100%;
   z-index:9998;
   .header__right{
  		z-index:1042;
  		@include media-breakpoint-desktop{
   			transform: translateY(60px);//79 ou 60  si on translate logo
	   		.btn-expand-close{
	   				transform: translateY(60px);
	   		}
   		}
   }
}

.headroom--unpinned{
	@include media-breakpoint-mobile{
		top:-85px;
	}	
}
.header__up{
  align-content: center;
}
;@import "sass-embedded-legacy-load-done:938";