$component-name: carousel;

.#{$component-name} {
  & &-inner {
    height: 100%;
  }

  & &-item {
    align-items: center;
    justify-content: center;
    height: 100%;

    &.active {
      display: flex;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: var(--slider-img-height);
    }
  }

  & &-content {
    width: 100%;

    p {
      color: var(--slider-text-color);
    }

    h2 {
      font-weight: var(--slider-title-weight);
    }

    h3 {
      font-weight: var(--slider-subtitle-weight);
    }
  }

  & &-indicators {
    button {
      width: 10px;
      height: 10px;
      background-color: var(--bs-white);
      border: 0;
      border-radius: 1000rem;

      &.active {
        background-color: var(--bs-primary);
      }
    }
  }

  & &-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  & &-caption {
    top: 50%;
    bottom: inherit;
    max-width: 1120px;
    text-align: left;
    transform: translateY(-50%);

    .caption-description {
      max-width: 20rem;

      h3 {
        margin: $slider-text-spacing 0;
        font-size: $slider-subtitle-size;
      }

      p {
        font-size: $slider-description-size;
      }
    }

    h2 {
      font-size: $slider-title-size;
    }
  }

  & &-control-prev,
  & &-control-next {
    top: 50%;
    height: var(--slider-control-height);
    background: var(--slider-control-bg);
    border-radius: var(--slider-control-radius);
    transform: translateY(-50%);
  }

  & &-control-prev {
    left: 3rem;
  }

  & &-control-next {
    right: 3rem;
  }
}

;@import "sass-embedded-legacy-load-done:960";