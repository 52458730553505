$component-name: footer;

.#{$component-name} {
  .title {
    &[aria-expanded="true"] {
      .material-icons.add {
        display: none;
      }

      .material-icons.remove {
        display: inline-block;
      }
    }

    &[aria-expanded="false"] {
      .material-icons.add {
        display: inline-block;
      }

      .material-icons.remove {
        display: none;
      }
    }
  }

  .collapse {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .copyright {
    margin-bottom: 0;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    & &__block {
      margin-bottom: 2.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    & &__block {
      margin-bottom: 1rem;
    }
  }

  & &__block {
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        color: var(--bs-white);
        transition: transform 0.25s ease-in-out;
      }

      &:not(.collapsed) {
        i {
          transform: rotate(180deg);
        }
      }

    }
  }
  
/*back to top*/
#link-main-container {
    transition: opacity .5s, visibility .5s;
  	opacity: 0;
  	visibility: hidden;
  	mix-blend-mode:difference;
  	color:$white;
  	fill:$white;
  	font-size:0.75rem;
  	position: fixed;
  	z-index:1;
  	bottom: 100px;
    right: 0px;
    border:0;
  	&:not(use) >svg{
  		z-index: 1000;
  		height:30px;
    	width: 36px;	
  	}
  	&.show {
  		opacity: 1;
  		visibility: visible;
		}
		span{
			display:block;
			line-height:1;
			max-width:45px;	
		}

  @include media-breakpoint-mobile { 
    &:hover{color:$primary;}
  }
  @include media-breakpoint-up(lg) {
	  #product &{
			bottom: 135px;
		}
	}
}

}

;@import "sass-embedded-legacy-load-done:944";