$component-name: header-top;

.#{$component-name} {
	
  a {
    color: var(--header-top-color);
  }

  &__right {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  &__right,
  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .form-select {
  	padding:0;
    padding-right: 1.5rem;
    background-color: var(--header-top-bg);
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>"));
    color: var(--header-top-color);
    background-position: right 0.12rem center;
    border: none;
        font-weight: 700;
    text-transform: uppercase;
    font-size: 0.875rem;

    option {
      text-align: left;
      font-weight:700;
      text-transform:uppercase;
       font-size:0.75rem;
    }
  }

  &-desktop {
    padding: 0.25rem 0;
  }

  @include media-breakpoint-down(sm) {
    .container {
      padding: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:940";