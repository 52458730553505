// General colors
$default-icon-color: var(--bs-gray-700);

// Footer
$footer-background-color: var(--bs-secondary);
$footer-heading-color: var(--bs-white);
$footer-text-color: var(--bs-white);

// Emailsubscriptions
$email-input-color: var(--bs-gray-300);
$email-input-border-color: var(--bs-gray-300);

;@import "sass-embedded-legacy-load-done:740";