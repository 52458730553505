$component-name: checkout__steps;

.#{$component-name} {
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;

  &__number {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    color: var(--bs-white);
    background: var(--bs-gray-500);
    border-radius: 100%;
  }

  &__item {
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 0.75rem;
      z-index: 0;
      width: 50%;
      height: 2px;
      content: "";
      background: var(--bs-gray-500);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__text {
    color: var(--bs-gray-500);
  }

  &--current {
    .#{$component-name}__number {
      background: var(--bs-primary);
    }

    .#{$component-name}__text {
      color: var(--bs-primary);
    }

    &::before {
      background: var(--bs-primary);
    }
  }

  &--success {
    .#{$component-name}__number {
      background: var(--bs-success);
    }

    .#{$component-name}__text {
      color: var(--bs-success);
    }

    &::before {
      background: var(--bs-success);
    }

    &::after {
      background: var(--bs-success);
    }
  }

  &__list {
    width: 100%;
    max-width: 45rem;
    margin: auto;
  }

  &__mobile {
    width: 100%;
  }
}

;@import "sass-embedded-legacy-load-done:906";