.pagination {
  margin-bottom: 0;

  .page-link i {
    width: auto;
    height: auto;
    font-size: 1.25rem;
  }

  &-container {
    align-items: center;
    margin: 1rem 0;
  }
}

;@import "sass-embedded-legacy-load-done:890";