.blockreassurance_product {
  span,
  p {
    display: block;
    line-height: 1.5rem;
  }
}
.blockreassurance{padding-top:1.5rem;text-align:center;width:100%}


.blockreassurance .reass-item{display:inline-block;text-align:center;vertical-align:top}
.blockreassurance .block-icon{margin-bottom:0.5rem;}
.blockreassurance .block-title{
	font-family:$headings-font-family;
	font-size:1.125rem;
	margin-bottom:0.5rem;
}
.blockreassurance .block-title+p{
	font-size:$small-font-size;
}
.blockreassurance .block-icon,.blockreassurance .block-icon img,.blockreassurance .block-icon svg{
	display:inline-block;
	height:60px;
}

.blockreassurance_product{margin-top:25px}
.blockreassurance_product .item-product{display:inline-block;height:35px;float:left;margin-right:5px;padding-top:5px;width:35px}
.blockreassurance_product .item-product img,.blockreassurance_product .item-product svg{height:35px;width:35px}
.blockreassurance_product p.block-title{line-height:40px}
body#checkout .container-blockreassurance{min-height:auto}


@include media-breakpoint-down(xl){
	.blockreassurance .swiper {padding-bottom: 1.5rem;}
}

@include media-breakpoint-mobile-portrait(){
	.blockreassurance{
		.row{
			margin-right:0;
			margin-left:0;	
		}	
	}
}
;@import "sass-embedded-legacy-load-done:1038";