@import "~@fontsource/inter/index.css";
@import "~@fontsource/roboto-slab/600.css";
@import "~@fontsource/roboto-slab/400.css";
@import "~@fontsource/roboto-slab/300.css";
@import "~@fontsource/open-sans/800.css";
@import "~@fontsource/open-sans/700.css";
@import "~@fontsource/open-sans/600.css";
@import "~@fontsource/open-sans/400.css";
@import "~@fontsource/open-sans/300.css";
@import "~@fontsource/open-sans/800-italic.css";
@import "~@fontsource/open-sans/700-italic.css";
@import "~@fontsource/open-sans/600-italic.css";
@import "~@fontsource/open-sans/400-italic.css";
@import "~@fontsource/open-sans/300-italic.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff");
  font-display: swap;
}

.material-icons {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-family: "Material Icons", Arial, sans-serif;
  font-size: 24px; /* Preferred icon size */
  font-style: normal;
  font-weight: 500;

  /* Support for IE. */
  font-feature-settings: "liga";

  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;

  /*! /* @noflip */
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  // stylelint-disable-next-line
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

;@import "sass-embedded-legacy-load-done:866";