.progress-ring {
  &__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  &__background-circle {
    stroke: var(--bs-gray-200);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}

;@import "sass-embedded-legacy-load-done:908";