$component-name: pagination;

.#{$component-name} {
  margin: 3rem 0;

  &-number {
    font-size: 1rem;
    color: var(--bs-gray-600);
  }
}

;@import "sass-embedded-legacy-load-done:987";