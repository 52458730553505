.rich-text {
  line-height: 1.5;

  table {
    @extend .table;
  }

 /* h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: var(--bs-gray-800);
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  h3 {
    margin-top: 1.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.75rem;
  }

  h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  h5 {
    margin-top: 1.25rem;
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
  }

  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child {
    margin-top: 0;
  }

  p,
  ul {
    font-size: 1rem;
  }

  ul {
    padding-left: 1.2rem;
    list-style: disc;
  }*/

  img {
    max-width: 100%;
    height: auto;
  }
}

;@import "sass-embedded-legacy-load-done:892";