$page-loader-background: rgba($white, 0.6);

.page-loader {
  --page-loader-background: #{$page-loader-background};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--page-loader-background);
}

;@import "sass-embedded-legacy-load-done:900";