$component-name: subcategories;

.subcategory {

  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  border: 1px  solid $input-border-color;
  border-radius: 4px;

  &__wrapper {
    margin-bottom: 1rem;
  }

  &__image {
    flex-shrink: 0;
    width: 4rem;
  }

  &__name {
    margin-bottom: 0;
    margin-left: 1rem;
    font-size: 1rem;
    color: var(--bs-gray-800);
  }

  @include media-breakpoint-down(md) {

    flex-direction: column;
    text-align: center;

    &__name {
      margin-bottom: 0;
      margin-left: 0;
    }

    &__image {
      margin-bottom: 1rem;
    }

  }
}

;@import "sass-embedded-legacy-load-done:983";