.contact-form {
	#contact &{
	  width: 75%;
    max-width: 450px;
    margin: 0 auto;
    @include media-breakpoint-down(lg){
    	width: 100%;
    		
    }
	}
  .form-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    
  }
}

;@import "sass-embedded-legacy-load-done:1032";