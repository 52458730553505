.product__details,
.product__features {
 
}

.detail {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 4px solid var(--bs-white);
  

  &__left {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    font-size:0.75rem;
  }

  &__title {
    font-weight: 400;
  }
}

;@import "sass-embedded-legacy-load-done:1081";