.page-product {
  .product {
    &__name {
      margin-bottom: 1rem;
      font-weight:400;
    }

    &__infos {
      .accordion-button {
        padding: 1.5rem 0;
        font-size: 1.25rem;
        font-weight: 400;
        box-shadow : none;
      }

      .accordion-body {
        padding: 0;
        font-weight:300;
        padding-bottom:1rem;
        dt{font-weight:400;}
      }
      
  		.accordion-item {	
				    &:last-child { border-bottom:var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-border-color);}
				}
    }

    &__images {
      margin-bottom: 1rem;

      .carousel {
        overflow: hidden;
        .carousel-inner{
        	.carousel-control-next,
        	.carousel-control-prev{
        		background:none;
        		opacity:1;	
        	}
        	.material-icons {
				  	font-size:2rem;
				  	color:$body-color;
					}
				}
      }
      
      picture>img{
      	background-color: var(--bs-gray-100);	
      }
    }

    &__description-short {
      margin-bottom: 0.75rem;
      font-size:0.875rem;
    }

    &__tax-label {
    }

    &__prices {
      font-size: 1.25rem;
      padding-bottom:0.5rem;
      margin-bottom:1rem;
    }

    &__current-price {
      font-weight: 800;
    }

    &__discount {
      margin-left: 0.5rem;
      color: var(--bs-gray-700);
    }

    &__price-regular {
      text-decoration: line-through;
    }
    &__unit-price{
    	color:$gray-500;	
    	/*font-size:0.75rem;*/
    }

    &__variants {
      .variant {
        margin-bottom: 1.5rem;

        ul {
          display: flex;
          align-items: center;

          li {
            &:first-of-type {
              span {
                margin-left: 0;
              }
            }
          }
        }

        .input-color {
          display: none;
        }

        .color {
          span {
            font-size: 0;
          }
        }
      }
    }

    &__add-to-cart {
      margin-bottom: 1rem;

      .product-actions__button button {
        width: 100%;
        height: 2.375rem;
      }
    }

    &-pack__item {
      border: 1px $gray-200 solid;
    }
  }

  @include media-breakpoint-down(md) {
    .carousel-control-next {
      right: 1rem;
    }

    .carousel-control-prev {
      left: 1rem;
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 3rem;
      height: 3rem;
    }
  }
}

// Needs to be outside of the .page-product scope because it can be inside a modal
.thumbnails__container {
  .thumbnail {
    img {
      border: 1px solid transparent;
    }

    &.active img {
      border: 1px solid $primary;
    }
  }
}


.product-reassurance{
	.cb-item-content{
		display:flex;	
		align-items:center;
		.custom-html{
			padding-left:1rem;	
		}
	}	
}

.product-accessories{
	.products {
		display:flex;
		/*padding:0 20px;*/
	}
	.product-miniature{
		@extend .col-with-padding;
		margin-bottom:0.25rem;
	
	}
		.swiper-slide {
      width: 80%;
    }

    .swiper-slide:nth-child(2n) {
      width: 80%;
    }

    .swiper-slide:nth-child(3n) {
      width: 80%;
    }
}
;@import "sass-embedded-legacy-load-done:1079";