$component-name: breadcrumb;

.#{$component-name} {
  padding:1.5rem 0 0.75rem 0;
  font-size: 0.875rem;

  a {
    color: var(--bs-gray-800);
  }

  &[data-depth="1"] {
    display: none;
  }

  &__wrapper {
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      .breadcrumb{
      	overflow: scroll;
      	flex-wrap:nowrap;
	      .breadcrumb-item{
	      	white-space:nowrap;
	      	& + .breadcrumb-item::before{float:none;}
	      }
    	}
    }

    @at-root .page-order & {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .page-product .breadcrumb-item:last-child {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:882";