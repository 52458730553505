$component-name: sitemap;

.#{$component-name} {
  ul a {
    display: block;
    padding: 0.2rem 0;
    color: var(--bs-gray-800);
  }

  h2 {
    margin-bottom: 1rem;
  }

  li > ul {
    margin-left: 1rem;

    a {
      color: var(--bs-gray-700);
    }
  }
}

;@import "sass-embedded-legacy-load-done:1005";