#customer-form {
  .form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

.register-form {
  &__login-prompt {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

;@import "sass-embedded-legacy-load-done:1070";