$component-name: product-miniature;

.#{$component-name} {
  margin-bottom: 1.25rem;

  .card {
    transition: 0.25s ease-out;

    &:hover {
    }
  }

  &:hover {
  }

  & &__image-container {
  	background-color:$gray-100;
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    transition: 0.25s ease-out;
  }

  & &__image {
    transition: 0.25s ease-out;
  }

  & &__link {
    position: relative;
    display: block;

    &:hover {

      .#{$component-name}__image-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  & &__title {
    margin-bottom: 0;
    font-size: 1rem;
    color:$body-color;
    font-family:$headings-font-family;
    .featured-products &{
    	font-size: 1.125rem;	
    }
  }

  & &__regular-price {
    color: var(--bs-gray-700);
  }

  & &__price {
    font-weight: 400;
  }

  & &__quickview {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    padding: 0.5rem;
    background-color: var(--bs-white);
    transition: 0.25s ease-out;

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }

  & &__quickview_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    text-decoration: none;
    border: 0;

    .material-icons {
      margin-right: 0.25rem;
      font-size: 1.25rem;
    }
  }

  & &__quickview_touch {
    position: absolute;
    right: 0.635rem;
    bottom: 0.635rem;
    z-index: 10;
    display: none;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0.125rem -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);

    @media (hover: none) and (pointer: coarse) {
      display: flex;
    }
  }

  .variant-links {
    > a:first-child {
      margin-left: 0;
    }
  }
  
  .wishlist-button-add{
  		background-color:transparent;
  		box-shadow : none;
  }
  .featured-products &{
  	.wishlist-button-add{display:none;}
  	.form-add-to-cart{display:none !important;}
	}
	.form-add-to-cart .btn-primary{
		font-weight:400;
		font-size:0.8125rem;
		.material-icons{font-size:1.1875rem;}
	}
}

;@import "sass-embedded-legacy-load-done:1012";