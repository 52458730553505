$form-text-color:                       $body-color;
$form-text-font-size:                   0.75rem;

$form-label-margin-bottom:              0.5rem;
$form-label-font-size:                  1rem;
$form-label-font-weight:                700;
$form-label-color:                      $body-color;


$input-color:                           $body-color;
$input-border-color:                    $gray-300;

$input-focus-border-color:              $secondary;
$input-focus-box-shadow:                none;

$form-check-input-border:               1px solid $secondary;
$form-check-input-focus-border:         $secondary;
$form-check-input-focus-box-shadow:     none;
$form-check-input-checked-bg-color:  $secondary;
$form-check-input-checked-border-color:  $secondary;
$form-check-input-checked-color:$secondary;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;

;@import "sass-embedded-legacy-load-done:727";