$component-name: address;

.#{$component-name} {
  background:$white;
  border: 1px solid $gray-300;

  &__content {
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: var(--bs-body-color);
    min-height:150px;
  }
  
  &__actions{
  	margin-bottom:0.5rem;
  	a{
  		text-decoration:underline;
	  	text-underline-position:under;	
	  	text-transform:lowercase;
  	}
  	
  }

  &__alias {
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1rem;
    color: var(--bs-body-color);
  }

  .card-footer {
    padding: 0 0.5rem;
    background: none;
  }

  &__edit,
  &__delete {
    display: inline-block;
    padding: 0.5rem 0.5rem;
    color: var(--bs-body-color);
    text-decoration: underline;
  }

  &__delete {
    color: var(--bs-danger);
  }
}

;@import "sass-embedded-legacy-load-done:1007";