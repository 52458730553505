$component-name: header-bottom;

.#{$component-name} {
  --header-top-border-bottom: #{$header-top-border-bottom};
  --header-bottom-border-bottom: #{$header-bottom-border-bottom};

  padding: 0;
  border-bottom: var(--header-bottom-border-bottom);
  .header--bg-transparent.headroom--top &{border-bottom:0;}
  #checkout &{border-bottom:0;}

  .navbar-brand {
    padding: 0;
  }

  .nav-link {
    &:active,
    &.active {
      color: var(--bs-primary);
    }

    &:active:hover {
      color: var(--bs-primary);
    }
  }
  
  &__container{
  	padding-top: 1.25rem;
    padding-bottom: 1.25rem;	
    .headroom--unpinned &,
		.headroom--pinned.headroom--not-top &{
			padding-top: 0rem;
    	padding-bottom:0.5rem;	
    }
    #checkout &{
    		padding-top: 1rem;
    		padding-bottom: 1rem;	
    }
  }
  &__row{
  	 .headroom--unpinned &,
		.headroom--pinned.headroom--not-top &{
    	margin-left:0;
    	width:100%;
    	&>div:first-child{display:none !important;}
		}	
  }
  
.header__logo{
	&:not(use) >svg.logo{
		fill:$body-color;
		max-height:48px;
		max-width: 296px;
		.header--bg-transparent.headroom--top &{
			fill:$white;	
		}
	}
	.headroom--unpinned &,
	.headroom--pinned.headroom--not-top &{
			 	z-index:1042;
 			@include media-breakpoint-desktop{
   			transform: translateY(60px);
	   		&:not(use) >svg.logo{
					max-height:33px;
					max-width:201px;
	   		}
   	 }
	}
}
  .header__right{
  	z-index:1046;
		.icon_svg:not(use) >svg{
			height:22px;
			width:20px;	
			fill:var(--bs-body-color);
			.header--bg-transparent.headroom--top &{
				fill:$white;
			}
		}
	}

	@include media-breakpoint-mobile{
	 &__container{
  	padding-top: 0.5rem;
    padding-bottom: 0.5rem;	
   }
	 .header__logo:not(use) >svg.logo{
				max-height:26px;
				max-width:170px;
		}
	  #menu-icon{
	  	padding:0;
	  	border:0;
	  	.material-icons{
	  			font-size:2.0625rem;
	  	}
	  }
	  /*.header__search {
	    width: 100%;
	    padding: 0;
	    flex: auto;
	    order: 10;
	    margin-top: 10px;
	  }
	  .header__logo--checkout{
	    margin: 0 auto;
	  }*/
	}
}

;@import "sass-embedded-legacy-load-done:1057";