$component-name: header-block;

.#{$component-name} {
  position: relative;

  &__action-btn {
    --header-block-spacing-x: #{$header-block-spacing-x};
    --header-block-spacing-y: #{$header-block-spacing-y};

    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: var(--header-block-spacing-y) var(--header-block-spacing-x);
    color: var(--header-top-color);
    border-radius: $btn-border-radius;

    &:hover,
    &:focus {
      color: var(--header-top-color);
    }

    @include media-breakpoint-up(md) {
      --header-block-spacing-x: #{$header-block-spacing-lg-x};
      --header-block-spacing-y: #{$header-block-spacing-lg-y};
    }
  }

  &__icon {
    @include media-breakpoint-up(md) {
      margin-right: 0.2em;
    }
  }

  &__badge {
    position: absolute;
    top: 0.4em;
    right: 5px;
    padding: 1px  3px 2px;
    font-size: 0.625rem;
    line-height:1;
    color: var(--bs-white);
    text-align: center;
    background: var(--bs-primary);
    border-radius:100%;

    @include media-breakpoint-up(md) {
    }
  }

  /* When active, on desktop everything is primary */
  &--active {
    @include media-breakpoint-up(lg) {
      .#{$component-name} {
        &,
        &__icon {
          color: var(--bs-primary);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:1024";