@use "sass:color";

$default-icon-color: var(--bs-primary) !default;


body,
html {
  min-height: 100%;
}

body {
  font-family: $font-family-base;
  color: var(--bs-body-color);
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  padding-left: 0;
  list-style: none;
}

a:not(.alert-link) {
  text-decoration: none;
  transition: 0.25s ease-out;

  .material-icons {
    transition: 0.25s ease-out;
  }
}

h2,.h2,h3,.h3,h4,.h4{
	font-weight:400;	
}

label .color,
.variant-links .color,
.custom-checkbox input[type="checkbox"] + span.color {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.3125rem;
  margin-right: 0.5rem;
  margin-left: 0;
  cursor: pointer;
  background-size: contain;
  border: 1px solid $input-border-color;
  border-radius: 4px;
  transition: 0.25s ease-out;

  &.color-sm {
    width: 1rem;
    height: 1rem;
  }

  &.active,
  &:hover {
    border: 2px solid $primary;
  }
}

.input-color {
  display: none;
}

.bright {
  color: var(--bs-gray-100);
}

.dark {
  color: var(--bs-gray-800);
}

/*@media (min-width: 1900px){
	.container-fluid{
		 max-width: 1920px; // blocage de la taille pour les 2560px	
	}
}*/

.col-with-padding {
	padding-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  padding-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
}

.col-with-margin {
	margin-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  margin-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
}

.row-fluid>* {
	padding-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  padding-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  &:first-child{
  	padding-left:0;	
  }
  &:last-child{
  	padding-right:0;	
  }
}


#gdpr_consent{
	label{
		font-style:italic;
		font-weight:300;
		font-size:12px;	
	}	
}


.table{
	th{
	/*	@extend h3;	*/
	}
	td,th{vertical-align:baseline;}	
	.badge{
		white-space	: inherit;
	}
}

.img-responsive{
	@extend .img-fluid;
}

#left-column>div,#right-column>div,#right-column>section {
    margin-bottom: 1rem;
}

.bg-light {
    background-color: var(--bs-light)!important;
}

.form-label.required{
	&:after{
		content:'*';
		color	: var(--bs-danger);
	}	
}
;@import "sass-embedded-legacy-load-done:868";