$component-name: store;

.#{$component-name} {
  margin-bottom: 1.5rem;

  .card {
    height: 100%;
    border: 1px solid $input-border-color;
  }

  .card-body,
  .card-footer {
    padding: 1.5rem;
    border: 1px solid $input-border-color;
  }

  .store__contacts {
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    i {
      margin-right: 0.4rem;
    }
  }

  img {
    margin-bottom: 1.5rem;
    border-radius: var(--bs-border-radius-sm);
  }

  address {
    line-height: 2rem;
  }

  table {
    ul {
      margin: 0;
    }

    th,
    td {
      line-height: 1.75rem;
    }

    th {
      padding-right: 1.6rem;
    }
  }
}

/*magasins*/
.fmmsl_store_hours_details table{
	@extend .table;	
	th,td{
		border:0px;
		border-top:1px solid $table-border-color;	
	}
}
;@import "sass-embedded-legacy-load-done:1003";