$component-name: footer;


.#{$component-name} {
  --footer-background-color: #{$footer-background-color};
  --footer-text-color: #{$footer-text-color};
  --footer-hover-color: var(--bs-gray-500);
  --footer-heading-color: #{$footer-heading-color};

  & &__main {
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
    background:var(--footer-background-color) url(../img/FRISE_footer.webp) no-repeat bottom;
    @include media-breakpoint-down(sm) {
    	background-image:url(../img/frise_footer_mobile.webp);
    }
    background-size:contain;
    @include font-size($small-font-size);
    font-weight:$font-weight-light;
    
  }

  & &-top {
    padding-bottom: 2.5rem;
  }

  h2,
  h3,
  .h4,
  .h3,
  .copyright {
    &,
    a {
      color: var(--bs-white);
    }
  }

  h2,
  h3,
  .h3,
  .h4 {
    margin-bottom: 1.5rem;
    font-size: 1rem;

    &,
    a {
      // stylelint-disable-next-line
      text-transform: inherit !important;
    }
  }

  @include media-breakpoint-down(md) {
    .copyright {
      font-size: 0.875rem;
    }
  }

  & &__block {

    &__content {
      padding-top: 0.25rem;
      color: var(--footer-text-color);
    }

    @include media-breakpoint-down(md) {
      &__content {
        font-size: 0.875rem;
      }
    }

    &__content-list {

      margin-bottom: 0;

      a {
        display: block;
        padding: 0.25rem;
        padding-left: 0;
        color: var(--footer-text-color);
        transition: 0.25s ease-out;

        &:hover {
          color: var(--footer-hover-color);
        }

        &.logout {
          color: #d5343c;
        }

      }

      @include media-breakpoint-down(md) {
        a {
          padding: 0.5rem;
          padding-left: 0;
        }
      }

    }

    &__content-contact {
      a {
        color: var(--footer-text-color);

        &:hover {
          color: var(--footer-hover-color);
        }
      }

      .contact__infos {
        margin-bottom: 1rem;
      }

      .contact__phone,
      .contact__fax {
        margin-bottom: 0.5rem;
      }

      > div:last-child {
        margin-bottom: 0;
      }

      .contact__infos,
      .contact__phone,
      .contact__fax,
      .contact__email {
        display: flex;
        align-items: center;

        i {
          margin-right: 0.5rem;
        }
      }
    }

    &__title {
    	font-family:$headings-font-family;
      font-size: 1.125rem;
      font-weight: 400;

      &,
      a {
        color: var(--footer-heading-color);
      }
    }

    @include media-breakpoint-up(md) {
      &__title {
      }
    }

    @include media-breakpoint-down(md) {
      &:last-child {
        margin-bottom: 2rem;
      }
    }

  }
  
  .footer__before{
  	background: var(--bs-white);	
  }
  
  &__main__bottom{
  	color:var(--bs-white);
  	a{color:var(--bs-white);}	
  	@include media-breakpoint-mobile(){
  		padding-top:2rem;	
  	}
  	#adveg-grecaptcha-legal{
  		padding:7px 0;	
  	}
  }
  
  .copyright{
  	padding-bottom:1.5rem;
  	font-size:$small-font-size;
  	font-weight:300;	
  	span{

				&:before{
					content: url(../img/icon_coeur.png);
    			padding-left: .1rem;
    			padding-right: 0.1rem;
				}	
				&:after{
					content: url(../img/icon-123.png);
    			padding-left: .1rem;	
				}	
			}
  }

}

;@import "sass-embedded-legacy-load-done:1059";